import clsx from 'clsx'
import type { ForwardedRef } from 'react'
import { forwardRef, memo, useCallback, useMemo, useRef } from 'react'
import { Badge } from '../../../../components/badge/badge'
import { FavoriteButton } from '../../../../components/icon-ctas/favorite-button'
import { ThreeDTourButton } from '@brand/slots/icon-ctas/three-d-tour-button'
import { Truncate } from '../../../../components/truncate/truncate'
import { getDealsText } from '../../../../features/listing/get-deals-text'
import { getListingPriceText } from '../../../../features/listing/get-listing-price-text'
import { ListingCardScrollSnapCarousel } from '../../../../features/listing-card/listing-card-scroll-snap-carousel'
import styles from './listing-card.module.css'
import { useSubmitUserActivityHandler } from '../../../../features/user-event-tracking/use-submit-user-activity-handler'
import { useTrackImpression } from '../../../../features/tagging/use-track-impression'
import { ShareButton } from '../../../../components/icon-ctas/share-button'
import { useShareProperty } from '../../../../features/detail/share-property/use-share-property'
import { BedCountData } from '../../../../features/listing-card/bed-count-data/bed-count-data'
import { ListingCardResultsCTA } from '../../../../features/listing-card/listing-card-results-cta'
import { useDebouncedCallback } from 'use-debounce'
import { SelectionOriginEnum } from '../../../../features/search/search-map/search-map.store'
import { useSetActiveListingAtom } from '../../../../features/search/hooks/use-active-listing-atom'
import { useListingCardImpressionTracking } from '../../../../features/listing-card/use-listing-card-impression-tracking'
import type {
  ListingCardProps,
  ListingHandleMouseOverParams,
  ListingCardHomePageProps,
} from '../../../../features/listing-card/listing-card.types'
import { useSetPlays } from '../../../../features/optimization-photos/use-set-plays'
import { useSetWins } from '../../../../features/optimization-photos/use-set-wins'
import { CONTACT_FOR_PRICE } from '@brand/slots/detail/floor-plans/const'
import { yieldOrContinue } from 'main-thread-scheduling'
import { ListingCardDetailPageLink } from '../../../../features/listing-card/listing-card-detail-page-link'
import { getAmenityText } from '../../../../features/listing-card/listing-card.utils'
import { PriceDropBadge } from '../../../../features/listing-card/price-drop-badge/price-drop-badge'
import { PropertyType } from '../../../../__generated__/api-types'
import { getSRPHdTours } from '../../../../features/listing-card/3d-tour-badge.util'

const MemoizedListingCardScrollSnapCarousel = memo(
  ListingCardScrollSnapCarousel
)

export const ListingCardBase = forwardRef(function ListingCardBase(
  props: ListingCardProps | ListingCardHomePageProps,
  forwardedRef: ForwardedRef<HTMLDivElement>
) {
  const { updatePlays, listing } = props
  const { simplifiedCard, hasTodaysTopPick } = props as ListingCardHomePageProps
  const listingCardIndex = (props.listingCardIndex || 0) + 1
  const firstListingInteraction = useRef(false)
  const listingPhotos = listing.optimizedPhotos || listing.photos
  const firstPhoto = listingPhotos?.[0] ? [listingPhotos[0]] : []
  const todaysTopPickText = `Today's Top Pick`
  const { location } = listing

  const cityState =
    location?.city && location?.stateAbbr
      ? `${location?.city}, ${location.stateAbbr}`
      : ''

  const updateWins = useSetWins(!!listing.optimizedPhotos?.length)

  const isSwipeEnabledForMobile =
    props.featureFlags?.srp_swipe_scroll_version === 1

  const setActiveListing = useSetActiveListingAtom()

  const submitUserActivitySrpInteraction = useSubmitUserActivityHandler({
    listingId: props.listing.id,
    type: 'srp-interaction',
  })

  const dealsBadgeText = getDealsText(listing)
  const hasCategoryBadges = listing.categoryBadges?.length > 0

  // Memoize the dataTagSection to ensure stability
  const dataTagSection = useMemo(
    () =>
      props.dataTagSection ||
      (props.isSpotlightListing && 'spotlight') ||
      (props.isFeaturedListing && 'featured_communities') ||
      (!listing.isUnpaid && 'paid') ||
      'free',
    [
      props.isSpotlightListing,
      props.isFeaturedListing,
      listing.isUnpaid,
      props.dataTagSection,
    ]
  )

  //filterMatchResult can be null when this component is accessed in the saved page.
  //In that case, we display the listing price as is.
  const listingPriceText = getListingPriceText({
    priceRange: props.filterMatchResult?.prices,
    isBasicListing: props.listing.isBasic,
    fallbackPriceText: props.listing.priceText,
    availabilityStatus: props.listing.availabilityStatus,
  })

  const isInterleavedApartment =
    props.isInterleavingEnabled &&
    props.listing.propertyType === PropertyType.Apartments

  const spotlightFeaturedText =
    props.isSpotlightListing || isInterleavedApartment
      ? 'Sponsored'
      : props.isFeaturedListing
      ? 'Featured'
      : ''

  const { shareHandler } = useShareProperty({ listing })

  const trackClickToPdp = useCallback(
    async function trackClickToPdp() {
      await yieldOrContinue('smooth')
      window.eventTracker?.track('click', {
        section: dataTagSection,
        item: props.dataTagItem ?? 'property_title',
        geo_sort_property_match_type: props.locationRelativeToSearchedCity,
        listing_id: props.listing.id,
        listing_index: listingCardIndex,
        availability_status: listing.availabilityStatus,
      })
    },
    [
      dataTagSection,
      props.dataTagItem,
      props.locationRelativeToSearchedCity,
      props.listing.id,
      listingCardIndex,
      listing.availabilityStatus,
    ]
  )

  function onMouseOver({ id, lat, lng }: ListingHandleMouseOverParams) {
    if (
      props.isActiveListing ||
      props.isMobile ||
      props.simplifiedCard ||
      props.closeMatchCard
    ) {
      return
    }

    // SRP -> Map indication
    if (props.isActiveListing) return
    if (lat && lng) {
      setActiveListing({
        selectionOrigin: SelectionOriginEnum.LIST,
        id,
        position: {
          lat,
          lng,
        },
      })
    }
  }

  const debouncedMouseOver = useDebouncedCallback(
    () =>
      onMouseOver({
        id: listing.id,
        lat: listing.location?.lat,
        lng: listing.location?.lng,
      }),
    150
  )

  const handleUpdateWins = useCallback(
    async function handleUpdateWins() {
      await yieldOrContinue('smooth')
      void updateWins({
        listingId: listing.id,
        photoId: listing.optimizedPhotos?.[0]?.id || '',
      })
    },
    [listing, updateWins]
  )

  const handleLinkClick = useCallback(
    async function handleLinkClick() {
      if (listing.urlPathname) {
        await yieldOrContinue('smooth')
        void handleUpdateWins()
      }
      await yieldOrContinue('smooth')
      void trackClickToPdp()
    },
    [listing.urlPathname, handleUpdateWins, trackClickToPdp]
  )

  const handleFirstInteraction = useCallback(
    async function handleFirstInteraction() {
      await yieldOrContinue('interactive')
      updatePlays({
        listingId: listing.id,
        photoId: listing.optimizedPhotos?.[0]?.id || '',
      })
    },
    [listing.id, listing.optimizedPhotos, updatePlays]
  )

  const srpInteractionHandler = useCallback(
    async function srpInteractionHandler() {
      if (!firstListingInteraction.current) {
        firstListingInteraction.current = true
        await yieldOrContinue('smooth')
        void submitUserActivitySrpInteraction()
      }
    },
    [submitUserActivitySrpInteraction]
  )

  const displayAddress = useMemo(() => {
    const { city, stateAbbr } = listing.location ?? {}

    if (
      city &&
      stateAbbr &&
      listing.zipCode &&
      listing.name &&
      listing.addressFull
        ?.toLowerCase()
        .startsWith(`${listing.name?.toLowerCase()},`)
    ) {
      return `${city}, ${stateAbbr} ${listing.zipCode}`
    }

    return listing.addressFull
  }, [listing])

  const displayedAmenities = useMemo(() => {
    return getAmenityText({
      refinementSlugs: props.currentRefinementSlugs ?? [],
      amenitiesHighlighted: listing.amenitiesHighlighted,
    })
  }, [listing.amenitiesHighlighted, props.currentRefinementSlugs])

  const threeDTourElement = !simplifiedCard ? (
    <ThreeDTourButton
      listingId={listing.id}
      tourInfo={getSRPHdTours(listing)}
      className={styles.threeDTourButton}
      tourFromHomeModalRef={props.tourFromHomeModalRef}
    />
  ) : null

  const firstPhotoOnly = props.simplifiedCard

  return (
    <div
      className={clsx(styles.card, {
        [styles.activeFromMap]: props.isActiveFromMapPin,
        [styles.carouselTodaysTopPick]: hasTodaysTopPick,
      })}
      ref={forwardedRef}
      data-tag_listing_id={props.listing.id}
      data-tag_listing_index={listingCardIndex}
      data-tag_section={dataTagSection}
      data-property-type={props.listing.propertyType}
      data-tplsource={props.listing.tplsource}
      onClick={srpInteractionHandler}
      onMouseOver={debouncedMouseOver}
      data-tid="listing-card"
    >
      {hasTodaysTopPick && (
        <div className={styles.todaysTopPick}>
          <p>{todaysTopPickText}</p>
        </div>
      )}
      <div className={styles.carouselSection}>
        {(Boolean(spotlightFeaturedText) || !props.isMobile) && (
          <div className={styles.textBackdrop} />
        )}

        {hasCategoryBadges && (
          <span className={styles.categoryBadgeText}>
            {listing.categoryBadges[0]}
          </span>
        )}

        {listing.hasHdTour && threeDTourElement}

        {Boolean(spotlightFeaturedText) && (
          <span className={styles.spotlightFeaturedText}>
            {spotlightFeaturedText}
          </span>
        )}
        <MemoizedListingCardScrollSnapCarousel
          lazyRenderSlides={props.lazyRenderSlides}
          listingId={listing.id}
          shouldUseFirstCarouselImageAsLCP={
            props.shouldUseFirstCarouselImageAsLCP
          }
          cityState={cityState}
          photos={firstPhotoOnly ? firstPhoto : listingPhotos}
          name={listing.name}
          urlPathname={listing.urlPathname}
          isMobile={props.isMobile}
          isUnpaid={listing.isUnpaid}
          onFirstInteraction={
            firstPhotoOnly ? undefined : handleFirstInteraction
          }
          location={listing.location}
          onLinkClick={handleLinkClick}
          className={styles.carousel}
          dataTagSection={dataTagSection}
          disableSwipe={!isSwipeEnabledForMobile && props.isMobile}
        />
      </div>

      <div className={styles.basicDetails}>
        <div className={styles.priceWrapper}>
          <span className={styles.price} data-tid="listing-price-text">
            {listingPriceText}
          </span>
          {/*
            https://redfin.atlassian.net/browse/WEB-17296:
            remove !props.isSavedListingCard once the saved page cards are responsive
          */}
          {listingPriceText !== CONTACT_FOR_PRICE &&
          !simplifiedCard &&
          !props.isSavedListingCard ? (
            <div className={styles.deals}>
              {dealsBadgeText ? (
                <Badge
                  data-tag_action="ignore"
                  data-tid="deals_badge"
                  text={dealsBadgeText}
                  className={styles.badge}
                />
              ) : (
                !listing.isUnpaid &&
                listing.hasPriceDrops && (
                  <PriceDropBadge
                    className={styles.priceDropBadge}
                    listing={listing}
                  />
                )
              )}
            </div>
          ) : null}
        </div>
        {!simplifiedCard && (
          <div className={styles.detailButtonsWrapper}>
            <ShareButton onClick={shareHandler} isAGListingCard />
            <FavoriteButton
              listingId={listing.id}
              isAGListingCard
              showSavedText
            />
          </div>
        )}
      </div>

      <ListingCardDetailPageLink
        isMobile={props.isMobile}
        className={styles.absoluteLink}
        href={listing.urlPathname ?? ''}
        onClick={handleLinkClick}
        data-tid="pdp-link"
      >
        <>
          <Truncate className={styles.nameAddressSection}>
            <span className={styles.name}>{listing.name}</span>{' '}
            <span>{displayAddress}</span>
          </Truncate>

          {displayedAmenities ? (
            <Truncate className={styles.amenitiesList}>
              {displayedAmenities}
            </Truncate>
          ) : null}
        </>
      </ListingCardDetailPageLink>

      {!simplifiedCard && (
        <BedCountData
          filterMatchResult={props.filterMatchResult}
          listing={props.listing}
          isSavedListingCard={props.isSavedListingCard}
        />
      )}

      {!listing.isBasic && !simplifiedCard && (
        <ListingCardResultsCTA
          ctaClass={styles.ctaButtons}
          listing={listing}
          currentRefinementSlugs={props.currentRefinementSlugs}
          isMobile={props.isMobile}
          locationRelativeToSearchedCity={props.locationRelativeToSearchedCity}
          onClick={handleUpdateWins}
        />
      )}
    </div>
  )
})

export const MemoizedListingCardBase = memo(ListingCardBase)

export function ListingCard(props: Omit<ListingCardProps, 'updatePlays'>) {
  const { isSRPCard, listing, filterMatchResult, ...restProps } = props
  const updatePlays = useSetPlays(!!listing.optimizedPhotos?.length)

  const { options, taggingData, imageConversion } =
    useListingCardImpressionTracking({
      listing,
      isSRPCard,
      updatePlays,
      filterMatchResult,
      locationRelativeToSearchedCity: props.locationRelativeToSearchedCity,
      listingCardIndex: props.listingCardIndex,
    })

  const ref = useTrackImpression(
    imageConversion.eventCatergory,
    options,
    taggingData
  )

  return (
    <MemoizedListingCardBase
      {...restProps}
      ref={ref}
      isSRPCard={isSRPCard}
      listing={listing}
      updatePlays={updatePlays}
      filterMatchResult={filterMatchResult}
    />
  )
}
